body {
    font-family: 'Roboto', sans-serif;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    overflow-x: hidden;
}
div p.tools {
    font-weight: 700;
}
div.portfolio-info:nth-child(even) .toolsLine {
    width: 40%;
    border: 0;
    height: 0.15rem;
    background-color: #64b5f6;
}

div.portfolio-info:nth-child(odd) .toolsLine {
    width: 40%;
    border: 0;
    height: 0.15rem;
    background-color: whitesmoke;
}

svg {
    fill: white;
}

h2 > i {
    margin-left: 1em;
}

i {
    cursor: pointer;
}

.footerArrow  {
    color: whitesmoke;
}

div.skillsSection.row {
   margin: auto;
}

a.btn:hover:nth-child(odd) {
    transform: scale(1.2);
    box-shadow: 0 0 10px whitesmoke;
}
a.btn:hover:nth-child(even) {
    transform: scale(1.2);
    box-shadow: 0 0 10px #64b5f6;
}

main {
    flex: 1 0 auto;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Adamina', serif;
}

nav {
    text-transform: uppercase;
}

footer {
    width: 100%;
}

div.portfolio-info:nth-child(odd) {
    background-color: #2196f3;
    color: whitesmoke;
}

div.portfolio-info:nth-child(odd) > div > div > a {
    color: #64b5f6;
    background-color: whitesmoke;
    margin: 1rem;
}

div.portfolio-info:nth-child(even) > div > div > a {
    background-color: #64b5f6;
    color: whitesmoke;
    margin: 1rem;

}

 .portfolio-img {
     max-width: 100%;
     height: auto;
     padding: 1rem;
 }


.nopadding {
    padding: 0;
}

.nomargin {
    margin:0;
}

.page-footer {
    margin: 0 auto;
    padding: .75rem 0;
    text-align: center;
}
div.col.marg {
    margin: 2rem;
}

div.row div.col {
    padding: 0;
    margin: 0;
}

div .sidenav {
    background-color: #64b5f6;
}
.pl {
     padding: 2em;
 }

.ms {
    margin: 0.5rem 0;
}

.marginSide {
    margin: 0 0.5rem;
}

.mst {
    margin-top: 0.25rem;
}

 .pst {
     padding: .25em 0em;
 }
 
.index-header {
    padding: 0;
    margin: 0;
    width: 100%;
}

.logoimg {
    max-height: 80px;
    margin: 2rem;
}
svg.logoimg {
    max-height: 80px;
    max-width: 80px;
    margin: 2rem;
}

.line {
    height: 0.15rem;
    width: 80%;
    background-color: white;
    margin-top: 2rem;
    border: none;
}

.line-blue {
    height: 0.25rem;
    width: 80%;
    background-color: #4fc3f7;
    margin: 1.5rem auto;
    border: none;
}


.material-icons {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}

.icon-left {
    margin-right: 0.75rem;
}

.sidenav {
    background-color: #64b5f6;
    color: white;
}

.sidenav-trigger {
    background-color: transparent;
    border: none; 
    box-shadow: none;
    color: white;
}

.sidenav-trigger:active {
    background-color: transparent;
    border: none; 
    box-shadow: none;
    color: white;
}

.sidenav li a {
    color:white;
    font-size: 1.5rem;
    margin: 0.75rem 0;
}

.narrowList {
    margin: 0 20%;
}

@media only screen and (max-width: 990px) {
    .reverse {
        display: flex;
        flex-direction: column-reverse;
    }
    
}
@media only screen and (max-width: 550px) {
    a.brand-logo.center {
        font-size: 1rem;
    }
    .logoimg {
        max-height: 50px;
        margin: 1rem;
    }
    svg.logoimg {
        width: 50px;
        height: 50px;
        margin:1rem;
    }
}